import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useShowBackgroundVideo } from '../../hooks/useShowBackgroundVideo';
import {
  BackgroundVideo,
  HeroModuleCallToAction,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { BodyWrapper, ShortTextWrapper, HeroModuleContent } from '../../styles';

export const VideoDisplayContent = (props) => {
  const {
    section: {
      videoId,
      videoSource,
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      playVideoOnMobile,
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
    } = {},
  } = props;
  const showBackgroundVideo = useShowBackgroundVideo(
    videoId,
    playVideoOnMobile,
  );

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const alignStyle = themeConfig.module.alignStyle;

  const { xs, sm, offset } =
    dStyles.elements?.content?.alignStyles?.[alignStyle]?.contentGrid;

  return (
    <>
      {showBackgroundVideo && (
        <BackgroundVideo videoId={videoId} videoSource={videoSource} />
      )}
      <div className="container">
        <div className="row">
          <HeroModuleContent
            className={`col-xs-${xs} col-sm-${sm} col-sm-offset-${offset}`}
          >
            <BodyWrapper>
              <ShortTextWrapper>
                {displayDataAndVenueInfo && <HeroModuleSummary />}
                <HeroModulePreTitle text={preTitle} />
                <HeroModuleTitle as={titleSize} text={title} />
                <HeroModuleSubTitle text={subTitle} />
                <HeroModuleText text={text} />
              </ShortTextWrapper>
            </BodyWrapper>
            <HeroModuleCallToAction
              primaryCTAConfig={primaryCTAConfig}
              secondaryCTAConfig={secondaryCTAConfig}
            />
          </HeroModuleContent>
        </div>
      </div>
    </>
  );
};

VideoDisplayContent.propTypes = {
  section: PropTypes.shape({
    titleSize: PropTypes.string,
    videoId: PropTypes.string,
    subText: PropTypes.string,
    valueProposition: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
    displayDataAndVenueInfo: PropTypes.bool,
  }).isRequired,
};
