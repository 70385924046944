import PropTypes from 'prop-types';
import { useGetImgixImageUrl } from '../../../../../../hooks';
import { useShowBackgroundVideo } from '../../hooks/useShowBackgroundVideo';
import {
  BackgroundVideo,
  HeroModuleCallToAction,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { Wrapper } from './Wrapper';
import { Column } from './Column';
import styled from 'styled-components';

const VenueWrapper = styled.div`
  text-transform: uppercase;
`;

export function ForegroundFiftyFiftyDisplayContent(props) {
  const {
    section: {
      valueProposition: title,
      titleSize,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
      background,
      videoId,
      videoSource,
      playVideoOnMobile,
      backgroundType,
      backgroundImageAnchor,
    },
  } = props;

  const foregroundImagePath = useGetImgixImageUrl(background?.path);
  const showBackgroundVideo = useShowBackgroundVideo(
    videoId,
    playVideoOnMobile,
  );

  return (
    <Wrapper>
      <Column className="content">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-8 col-lg-offset-2">
              {displayDataAndVenueInfo && (
                <VenueWrapper>
                  <HeroModuleSummary />
                </VenueWrapper>
              )}
              <HeroModuleTitle as={titleSize} text={title} styled />
              <HeroModuleText text={text} />
              <HeroModuleCallToAction
                primaryCTAConfig={primaryCTAConfig}
                secondaryCTAConfig={secondaryCTAConfig}
              />
            </div>
          </div>
        </div>
      </Column>
      <Column
        className="foreground lazy"
        data-background-image={foregroundImagePath}
        backgroundImageAnchor={backgroundImageAnchor}
      >
        {showBackgroundVideo && backgroundType === 'VIDEO' && (
          <BackgroundVideo
            videoId={videoId}
            videoSource={videoSource}
            withTransparentOverlay
          />
        )}
      </Column>
    </Wrapper>
  );
}

ForegroundFiftyFiftyDisplayContent.propTypes = {
  section: PropTypes.shape({
    valueProposition: PropTypes.string,
    titleSize: PropTypes.string,
    text: PropTypes.string,
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
    displayDataAndVenueInfo: PropTypes.bool,
    background: PropTypes.object,
    backgroundType: PropTypes.string,
    videoId: PropTypes.string,
    videoSource: PropTypes.string,
    playVideoOnMobile: PropTypes.bool,
  }).isRequired,
};
