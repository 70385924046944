import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const Column = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles?.elements?.column)}

  &.content {
    position: relative;
    z-index: 1;

    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.module.vStyles?.elements?.column)}
  }
`;
