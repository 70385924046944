import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import {
  HeroModuleCallToAction,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { HeroModuleTextBox, HeroModuleTextBoxWrapper } from '../../styles';

export const StandardDisplayContent = (props) => {
  const {
    section: {
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
    } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;

  const { xs, md, offset } = dStyles.contentGrid;

  return (
    <HeroModuleTextBox data-testid="hero-module-text-box">
      <div className="container">
        <div className="row">
          <div className={`col-xs-${xs} col-md-${md} col-md-offset-${offset}`}>
            <HeroModuleTitle as={titleSize} text={title} styled />
          </div>
          <div className={`col-xs-${xs} col-md-${md}`}>
            <HeroModuleTextBoxWrapper>
              {displayDataAndVenueInfo && <HeroModuleSummary condensed />}
              <HeroModuleSubTitle text={subTitle} />
              <HeroModuleText text={text} />
              <HeroModuleCallToAction
                primaryCTAConfig={primaryCTAConfig}
                secondaryCTAConfig={secondaryCTAConfig}
              />
            </HeroModuleTextBoxWrapper>
          </div>
        </div>
      </div>
    </HeroModuleTextBox>
  );
};

StandardDisplayContent.propTypes = {
  section: PropTypes.shape({
    subText: PropTypes.string,
    valueProposition: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
  }).isRequired,
};
