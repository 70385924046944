import styled from 'styled-components';
import { CallToAction } from '../../../sharedComponents';

export const CarouselItemBackgroundLink = styled(CallToAction)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;
