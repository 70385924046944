import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { getImgixUrl } from '../../../../../../helpers';
import { ImageTag } from '../../../sharedComponents';
import {
  HeroModuleCallToAction,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import {
  BodyWrapper,
  ForegroundImageRightWrapper,
  HeroModuleContent,
  ForegroundImageLogoRightWrapper,
} from '../../styles';

export const ImageRightDisplayContent = (props) => {
  const {
    tenantId,
    section: {
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      foreground = {},
      displayDataAndVenueInfo,
      displayForegroundImageOnMobileDevice,
    } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;

  const { xs, sm } = dStyles.contentGrid;

  return (
    <>
      <div className="container">
        <div className="row">
          <HeroModuleContent className={`col-xs-${xs} col-sm-${sm}`}>
            <BodyWrapper>
              <div>
                {displayDataAndVenueInfo && <HeroModuleSummary condensed />}
                <HeroModulePreTitle text={preTitle} />
                <HeroModuleTitle as={titleSize} text={title} />
                <HeroModuleSubTitle text={subTitle} />
                <HeroModuleText text={text} />
              </div>
            </BodyWrapper>
            {displayForegroundImageOnMobileDevice && foreground?.path && (
              <ForegroundImageLogoRightWrapper
                className="lazy"
                data-background-image={getImgixUrl(tenantId, foreground.path)}
                data-testid="hero-image-foreground-logo"
              >
                <ImageTag imageConfig={foreground} />
              </ForegroundImageLogoRightWrapper>
            )}
            <HeroModuleCallToAction
              primaryCTAConfig={primaryCTAConfig}
              secondaryCTAConfig={secondaryCTAConfig}
            />
          </HeroModuleContent>
        </div>
      </div>
      {foreground?.path && (
        <ForegroundImageRightWrapper
          className="lazy"
          data-background-image={getImgixUrl(tenantId, foreground.path)}
          data-testid="hero-image-foreground"
        >
          <ImageTag imageConfig={foreground} />
        </ForegroundImageRightWrapper>
      )}
    </>
  );
};

ImageRightDisplayContent.propTypes = {
  tenantId: PropTypes.string,
  section: PropTypes.shape({
    subText: PropTypes.string,
    valueProposition: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    foreground: PropTypes.shape({
      path: PropTypes.string,
    }),
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
    displayDataAndVenueInfo: PropTypes.bool,
    displayForegroundImageOnMobileDevice: PropTypes.bool,
  }).isRequired,
};
