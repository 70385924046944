import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const Wrapper = styled.div`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles?.elements?.wrapper)}
`;
