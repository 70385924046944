import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import {
  HeroModuleCallToAction,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { BodyWrapper, HeroModuleContent } from '../../styles';

export const LightDisplayContent = (props) => {
  const {
    section: {
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
    } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const alignStyle = themeConfig.module.alignStyle;

  const { xs, sm, offset } =
    dStyles.elements?.content?.alignStyles?.[alignStyle]?.contentGrid;

  return (
    <div className="container">
      <div className="row">
        <HeroModuleContent
          className={`col-xs-${xs} col-sm-${sm} col-sm-offset-${offset}`}
        >
          <BodyWrapper>
            <div>
              {displayDataAndVenueInfo && <HeroModuleSummary condensed />}
              <HeroModulePreTitle text={preTitle} />
              <HeroModuleTitle as={titleSize} text={title} styled />
              <HeroModuleSubTitle text={subTitle} />
              <HeroModuleText text={text} />
            </div>
          </BodyWrapper>
          <HeroModuleCallToAction
            primaryCTAConfig={primaryCTAConfig}
            secondaryCTAConfig={secondaryCTAConfig}
          />
        </HeroModuleContent>
      </div>
    </div>
  );
};

LightDisplayContent.propTypes = {
  section: PropTypes.shape({
    subText: PropTypes.string,
    valueProposition: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
  }).isRequired,
};
