import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { ImageTag } from '../../../sharedComponents';
import {
  HeroModuleCallToAction,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { BodyWrapper, ShortTextWrapper, HeroModuleContent } from '../../styles';
import { renderStyledElementStyles } from '../../../../helpers';

const ImageTagWrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.imageWrap)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.imageWrap)}
`;

export const ImageDisplayContent = (props) => {
  const {
    section: {
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      foreground = {},
      displayDataAndVenueInfo,
      displayForegroundImageOnMobileDevice,
    } = {},
    contentBlock,
  } = props;
  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const alignStyle = themeConfig.module.alignStyle;

  const { xs, sm, offset } =
    dStyles.elements?.content?.alignStyles?.[alignStyle]?.contentGrid;

  const foregroundDStyles =
    dStyles.elements?.foreground?.alignStyles?.[alignStyle];

  const foregroundLogoDStyles =
    dStyles.elements?.foregroundLogo?.alignStyles?.[alignStyle];

  return (
    <div className="container">
      <div className="row">
        <HeroModuleContent
          className={`col-xs-${xs} col-sm-${sm} col-sm-offset-${offset}`}
        >
          <BodyWrapper>
            <ShortTextWrapper data-testid="hero-module-short-text-wrapper">
              {displayDataAndVenueInfo && <HeroModuleSummary condensed />}
              <HeroModulePreTitle text={preTitle} />
              <HeroModuleTitle as={titleSize} text={title} />
              <HeroModuleSubTitle text={subTitle} />
              <HeroModuleText text={text} />
            </ShortTextWrapper>
          </BodyWrapper>
          {displayForegroundImageOnMobileDevice && (
            <ImageTagWrapper
              displayStyles={foregroundLogoDStyles}
              data-testid="hero-image-foreground-logo"
            >
              <ImageTag
                imageConfig={foreground}
                displayStyles={foregroundLogoDStyles}
              />
            </ImageTagWrapper>
          )}
          <HeroModuleCallToAction
            primaryCTAConfig={primaryCTAConfig}
            secondaryCTAConfig={secondaryCTAConfig}
          />
        </HeroModuleContent>
        {contentBlock}
        <div className="col-xs-12" data-testid="hero-image-tag">
          <ImageTagWrapper displayStyles={foregroundDStyles}>
            <ImageTag
              imageConfig={foreground}
              displayStyles={foregroundDStyles}
            />
          </ImageTagWrapper>
        </div>
      </div>
    </div>
  );
};

ImageDisplayContent.propTypes = {
  section: PropTypes.shape({
    subText: PropTypes.string,
    valueProposition: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    foreground: PropTypes.shape({
      path: PropTypes.string,
    }),
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
    displayDataAndVenueInfo: PropTypes.bool,
    displayForegroundImageOnMobileDevice: PropTypes.bool,
  }).isRequired,
  contentBlock: PropTypes.element,
};
