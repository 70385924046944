import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const Column = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles?.elements?.column)}

  &.foreground {
    position: relative;
    background-position: ${({ backgroundImageAnchor }) => {
      if (backgroundImageAnchor === 'LEFT') return 'left';
      if (backgroundImageAnchor === 'RIGHT') return 'right';
      return 'center';
    }};
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 767px) {
      position: relative;
      overflow: hidden;
      width: 100%;
      margin: 0;
      padding: 56.25% 0 0;
    }
  }
`;
