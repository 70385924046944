import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles?.elements?.column)}

  &.content {
    position: relative;
    z-index: 1;
    flex: 1;
  }
`;
