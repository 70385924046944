import styled from 'styled-components';

export const HeroCarouselModuleWrapper = styled.div`
  position: relative;
  text-align: center;

  .slick-dots {
    bottom: 22px;

    li {
      width: auto;
      height: auto;
      margin: 0 10px;

      button {
        font-size: 0;
        border: 2px solid #fff;
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transition: 0.2s;

        &:before {
          display: none;
        }

        &:hover {
          background-color: transparent;
        }
      }

      &.slick-active {
        button {
          background: ${({ theme }) => theme.brandColors.primary};
        }
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    width: 84px;
    height: 84px;
    background-color: transparent;
    border: none;
    text-align: center;

    &:before {
      display: inline-block;
      font-family: inherit;
      content: '';
      margin: 0;
      transition: 0.2s;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
    }

    &:hover {
      box-shadow: none;

      &:before {
        transform: scale(1.2);
      }
    }
  }

  .slick-next {
    right: 12px;

    &:before {
      border-left: 18px solid white;
    }
  }

  .slick-prev {
    left: 12px;

    &:before {
      border-right: 18px solid white;
    }
  }

  .slick-slide {
    transform: translate3d(0, 0, 0);
  }
`;
