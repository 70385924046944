import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

export const SlideWrapper = styled.div`
  position: relative;
  background: center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;
