import PropTypes from 'prop-types';
import { useGetImgixImageUrl } from '../../../../../../hooks';
import {
  HeroModuleCallToAction,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { Wrapper } from './Wrapper';
import { Column } from './Column';
import { Overlay } from '../../../HeroCarouselModule/styled';

export function HalfStackedDisplayContent(props) {
  const {
    section: {
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
      background,
      textBlockBackgroundImage,
      textBlockColor,
    },
  } = props;

  const backgroundImagePath = useGetImgixImageUrl(background?.path);
  const textBlockBackgroundImagePath = useGetImgixImageUrl(
    textBlockBackgroundImage?.path,
  );
  const textBlockStyle = { color: textBlockColor };

  return (
    <Wrapper>
      <Column
        className="content lazy"
        data-background-image={textBlockBackgroundImagePath}
      >
        <Overlay />
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
              {displayDataAndVenueInfo && (
                <HeroModuleSummary condensed style={textBlockStyle} />
              )}
              <HeroModulePreTitle text={preTitle} style={textBlockStyle} />
              <HeroModuleTitle
                as={titleSize}
                text={title}
                style={textBlockStyle}
                styled
              />
              <HeroModuleSubTitle text={subTitle} style={textBlockStyle} />
              <HeroModuleText text={text} style={textBlockStyle} />
              <HeroModuleCallToAction
                primaryCTAConfig={primaryCTAConfig}
                secondaryCTAConfig={secondaryCTAConfig}
                style={textBlockStyle}
              />
            </div>
          </div>
        </div>
      </Column>
      <Column className="lazy" data-background-image={backgroundImagePath} />
    </Wrapper>
  );
}

HalfStackedDisplayContent.propTypes = {
  section: PropTypes.shape({
    subText: PropTypes.string,
    valueProposition: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
    displayDataAndVenueInfo: PropTypes.bool,
    background: PropTypes.object,
  }).isRequired,
};
